.home-container {
  padding: 3em;
  max-width: 80rem;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
}
