.home-container {
  padding: 3em;
  max-width: 80rem;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
  align-items: stretch;
}

.tags {
  max-width: 80rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  align-self: center;
}

button {
  flex: 1;
  background-color: #275A60;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
button:hover {
  background-color: blue;
}