.post-preview {
  border-radius: 3px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}

.img {
  border-radius: 3px;
  display: block;
  margin: auto;
}

.post-preview h2 {
  width: 300px;
  word-wrap: break-word;
}